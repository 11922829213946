<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
      <!-- <a href="mailto:379454125@qq.com?subject=商务合作">商务合作</a>
      <a href="mailto:379454125@qq.com?subject=定制开发">定制开发</a>
      <a href="mailto:379454125@qq.com?subject=问题反馈">问题反馈</a> -->
    </template>
    <template v-slot:copyright>
      <a href="https://beian.miit.gov.cn/">粤ICP备2020082550号</a><br>
      <a>@热爱生活健康科技(深圳)有限公司版权所有</a>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
