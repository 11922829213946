import { asyncRouterMap, constantRouterMap } from '@/config/router.config'

/**
 * 单账户多角色时，使用该方法可过滤角色不存在的菜单
 *
 * @param menus
 * @param roles
 * @param route
 * @returns {*}
 */
// eslint-disable-next-line
function hasRole(roles, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.filter(role => roles.includes(role)).length > 0
  } else {
    return true
  }
}

function filterAsyncRouter (routerMap, roles) {
  const accessedRouters = routerMap.filter(route => {
    if (hasRole(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

function hasMenu(menus, route) {
  if (route.name == "Home" || route.name == "index" || route.hidden) {
    return true
  }
  if (route.name) {
    return menus.indexOf(route.name) >= 0
  } else {
    return true
  }
}

function newFilterAsyncRouter (routerMap, menus) {
  const accessedRouters = routerMap.filter(route => {
    if (hasMenu(menus, route)) {
      if (route.children && route.children.length) {
        route.children = newFilterAsyncRouter(route.children, menus)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        const { roles, menus, username } = data
        if (username == "admin") {
          commit('SET_ROUTERS', asyncRouterMap)
        } else {
          const accessedRouters = newFilterAsyncRouter(asyncRouterMap, menus);
          try {
            for (const item of accessedRouters[0].children) {
              if (item.children && item.children.length > 0) {
                let hasFirst = false;
                let pathFirst = "";
                for (const child of item.children) {
                  if (item.redirect == child.path) {
                    hasFirst = true
                  }
                  if (!pathFirst && !child.hidden) {
                    pathFirst = child.path
                  }
                }
                if (!hasFirst) {
                  item.redirect = pathFirst;
                }
              }
            }
            accessedRouters[0].redirect = accessedRouters[0].children[0].path;
          } catch (error) {
            console.log(error);
          }
          console.log(accessedRouters[0]);
          commit('SET_ROUTERS', newFilterAsyncRouter(asyncRouterMap, menus))
        }
        resolve()
      })
    }
  }
}

export default permission
