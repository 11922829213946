<!--<template>-->
<!--  <keep-alive>-->
<!--  <router-view :key="key"></router-view>-->
<!--  </keep-alive>-->
<!--</template>-->
<script>
export default {
  computed: {
    key() {
      return this.$route.path + Date.now()
    }
  },
  name: 'RouteView',
  props: {
    keepAlive: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {

    }
  },
  render () {
    let kky = this.$route.path ;
    const { $route: { meta }, $store: { getters } } = this
    const inKeep = (
      <keep-alive>
        <router-view/>
      </keep-alive>
    )
    const notKeep = (
      <router-view />
    )
    // debugger;

    // 这里增加了 multiTab 的判断，当开启了 multiTab 时
    // 应当全部组件皆缓存，否则会导致切换页面后页面还原成原始状态
    // 若确实不需要，可改为 return meta.keepAlive ? inKeep : notKeep
    if(meta.keepAlive){
      // console.log("keep")
      return inKeep;
    }else{
      // console.log("NotKeep")
      return notKeep;
    }
    // if (!getters.multiTab && !meta.keepAlive) {
    //   return notKeep
    // }
    // console.log("this.keepAlive:"+this.keepAlive);
    // console.log("getters.multiTab:"+getters.multiTab);
    // console.log("meta.keepAlive:"+meta.keepAlive);
    // let flag = this.keepAlive || getters.multiTab || meta.keepAlive ? inKeep : notKeep;
    // console.log("result:"+flag);
    // return flag;
  }
}
</script>
