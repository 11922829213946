const user = {
  state: {
    expressList: ["公司直提免物流", "申通快递","德邦物流","顺丰快递"],
    stickyList: [{
      id: "0",
      name: "庆功宴"
    },{
      id: "1",
      name: "新人启航"
    },{
      id: "2",
      name: "财富说明会"
    },{
      id: "3",
      name: "领袖演说"
    },{
      id: "4",
      name: "云店内训"
    },{
      id: "5",
      name: "服务中心内训"
    },{
      id: "6",
      name: "开业答谢"
    },{
      id: "7",
      name: "沙龙会"
    },{
      id: "8",
      name: "内训会"
    },{
      id: "9",
      name: "招商会"
    },{
      id: "10",
      name: "精英训练营"
    },],
  },

  mutations: {
    
  },

  actions: {
    

  }
}

export default user
