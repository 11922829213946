import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('parseMoney', function (dataStr) {
  return parseFloat(Number(dataStr).toFixed(2));
})


Vue.filter('paymentMethod', function (value, discount) {
  let text = "";
  switch (value) {
    case "money":
      if (discount && discount > 0) {
        text = "微信折扣"
      } else {
        text = "微信支付"
      }
      break;
    case "trans":
      if (discount && discount > 0) {
        text = "扫码折扣"
      } else {
        text = "扫码支付"
      }
      break;
    case "cash":
      text = "现金折扣"
      break;
    default:
      break;
  }
  return text
})