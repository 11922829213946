import axios from 'axios'

export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
      variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}

export function getUserTypeName (type) {
  let name = ""
  switch (type) {
    case 'normal':
      name = '会员'
      break;
    case 'store':
      name = '云店'
      break;
    case 'center':
      name = '服务中心'
      break;
    case 'president':
      name = '运营总裁'
      break;
    default:
      break;
  }
  return name
}


export function getSpreaderMobileList(mobile, type, spreaderType) {
  type = parseInt(type || 0);
  const spreaderMobileList = [];
  if (mobile) {
    const len = mobile.length;
    let type0 = 0;
    switch (spreaderType) {
      case "store":
        type0 = 1;
        break;
      case "center":
        type0 = 2;
        break;
      case "president":
        type0 = 3;
        break;
      default:
        type0 = 0;
        break;
    }
    spreaderMobileList.push({
      color: "#E62734",
      text: type0 > 0 ? mobile.substr(0, type0) : ""
    });
    spreaderMobileList.push({
      color: "",
      text: mobile.substr(type0, len - type - type0)
    });
    spreaderMobileList.push({
      color: "#1890ff",
      text: type > 0 ? mobile.substr(len - type, type) : ""
    });
  }
  return spreaderMobileList
}


export function isVideoUrl(url) {
  return url.search(/.avi$|.rmvb$|.rm$|.asf$|.divx$|.mpg$|.mpeg$|.mpe$|.wmv$|.mp4$|.mkv$|vob$/i) >= 0
}

//用于把文件下载到本地,主要解决下载图片问题
export function getDownload(url, name) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url,
      responseType: "blob"
    })
      .then(data => {
        console.log(data);
        let url = window.URL.createObjectURL(new Blob([data.data]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute(
          "download",
          name ? name : url.split("/")[url.split("/").length - 1]
        ); // 自定义下载文件名（如exemple.txt）
        document.body.appendChild(link);
        link.click();
        resolve();
      })
      .catch(error => {
        console.log(error);
        reject(error.toString());
      });
  });
}

export function showOrderType(result) {
  //购买人直接支付给公司
  let payToCompany =  (result.merchantMember == undefined || result.merchantMember == "" ||result.merchantMember ==null)||result.member== result.merchantMember;
  //用户/商户 支付给商户
  let payToMerchant = result.merchantMember!=null && result.member != null && result.member != result.merchantMember;
  //后台录单
  // let backRecord = result.id.substring(result.id.length-1,result.id.length)=='H';

  let tempNickName = result.operatorNickname;
  let backRecord = tempNickName=='后台录单';
  let merchantRecord = tempNickName=='商户录单';
  // debugger;
  let isShowOne = false;
  let isShowTwo = false;
  let isShowThree = false;

  if(backRecord){
    //后台录单 -只显示第一部份，其他两部份不显示
    isShowOne = true;

  }else{
    //是否显示第一部份
    isShowOne = payToCompany || payToMerchant;
    //是否显示第二部份-用户/商户支付给商户后，商户支付给公司
    isShowTwo = result.merchantMember!=null && result.member != null && result.member != result.merchantMember  && result.merchantOrderStatus>=1;


  }


  let memberFlag =(result.paymentMethod=="bank_trans"||result.paymentMethod=="trans");
  let merchantFlag =(result.merchantPaymentMethod=="bank_trans"||result.merchantPaymentMethod=="trans")
  // debugger;
  //是否显示第三部分-商户支付给公司时选择转账或扫码支付
  isShowThree = merchantFlag || memberFlag


  result.isShowOne = isShowOne;
  result.isShowTwo = isShowTwo;
  result.isShowThree = isShowThree;
  result.backRecord = backRecord;//后台录单
  result.merchantRecord = merchantRecord;//商户录单

  return result;
}

export function getFuZeRen(result) {
  let payMethod = "";
  let fuZeRen ="";
  if(result.merchantMember){
    //商户
    payMethod = result.merchantPaymentMethod;
  }else{
    //用户
    payMethod = result.paymentMethod;
  }

  if((payMethod==='bank_trans'||payMethod==='trans')&&result.bankTransBuyerName){
    //转账支付，设置
    fuZeRen = result.bankTransBuyerName;
  }else{
    fuZeRen = result.masterName;
  }


  return fuZeRen;
}