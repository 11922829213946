import request from '@/utils/request'

export function putInsideStaff(parameter) {
  return request({
    url: '/member/add/employee',
    method: 'post',
    data: parameter
  })
}

export function updateInsideStaff(parameter) {
  return request({
    url: '/member',
    method: 'put',
    data: parameter
  })
}

export function queryInsidePosition(parameter) {
  return request({
    url: '/permission/role',
    method: 'post',
    data: parameter
  })
}

export function deleteInsidePosition(parameter) {
  return request({
    url: '/permission/role',
    method: 'DELETE',
    params: parameter
  })
}

export function putInsidePosition(parameter) {
  return request({
    url: '/permission/role',
    method: 'put',
    data: parameter
  })
}

export function getInsidePosition(parameter) {
  return request({
    url: '/permission/role',
    method: 'get',
    params: parameter
  })
}

export function queryInsideStorehouse(parameter) {
  return request({
    url: '/depository/list',
    method: 'GET',
    params: parameter
  })
}

export function putInsideStorehouse(parameter) {
  return request({
    url: '/depository/add',
    method: 'put',
    data: parameter
  })
}

export function updateInsideStorehouse(parameter) {
  return request({
    url: '/depository/update',
    method: 'post',
    data: parameter
  })
}

export function deleteInsideStorehouse(parameter) {
  return request({
    url: '/depository/delete/' + parameter.id,
    method: 'DELETE'
  })
}

export function queryInsideStorehouseProd(parameter) {
  return request({
    url: '/depository/storage/production/list',
    method: 'GET',
    params: parameter
  })
}

export function deleteInsideStorehouseProd(parameter) {
  return request({
    url: '/depository/storage/delete/' + parameter.id,
    method: 'DELETE'
  })
}

export function queryInsideStorehouseProdList(parameter) {
  return request({
    url: '/depository/storage/list',
    method: 'GET',
    params: parameter
  })
}

export function queryInsideProd(parameter) {
  return request({
    url: '/depository/production/list',
    method: 'get',
    params: parameter
  })
}

export function putInsideProd(parameter) {
  return request({
    url: '/depository/production/add',
    method: 'put',
    data: parameter
  })
}

export function deleteInsideProd(parameter) {
  return request({
    url: '/depository/production/delete/' + parameter.id,
    method: 'DELETE'
  })
}

export function putInsideProdStorehouse(parameter) {
  return request({
    url: '/depository/storage/add',
    method: 'put',
    data: parameter
  })
}

export function putInsideProdExchange(parameter) {
  return request({
    url: '/depository/storage/exchange',
    method: 'put',
    data: parameter
  })
}

export function putInsideProdOut(parameter) {
  return request({
    url: '/depository/storage/out',
    method: 'put',
    data: parameter
  })
}

export function getChildDashboard(parameter) {
  return request({
    url: '/depository/storage/statistics',
    method: 'GET',
    params: parameter
  })
}

export function putInsideFile(parameter) {
  return request({
    url: '/depository/file/upload',
    method: 'post',
    data: parameter
  })
}

export function deleteInsideFile(parameter) {
  return request({
    url: '/depository/file/delete/' + parameter.id,
    method: 'delete'
  })
}

export function getInsideFile(parameter) {
  return request({
    url: '/depository/file/list',
    method: 'get',
    params: parameter
  })
}

export function getUnreadMessage(parameter) {
  return request({
    url: '/system/message/unread/count',
    method: 'get',
    params: parameter
  })
}

export function getMessage(parameter) {
  // debugger;
  return request({
    url: '/system/message/page',
    method: 'post',
    data: parameter
  })
}

export function readMessage(parameter) {
  let urlID = parameter.id;
  return request({
    url: '/system/message/read/'+urlID,
    method: 'post',
    data: parameter
  })
}

export function deleteMessage(parameter) {
  let urlID = parameter.id;
  return request({
    url: '/system/message/delete/'+urlID,
    method: 'DELETE',
    params: parameter
  })
}

export function queryWhiteList(parameter) {
  return request({
    url: '/user/ip/whitelist',
    method: 'post',
    data: parameter
  })
}

export function addWhiteList(parameter) {
  return request({
    url: '/user/ip/whitelist/add',
    method: 'post',
    data: parameter
  })
}

export function deleteWhiteList(parameter) {
  let urlID = parameter.id;
  return request({
    url: '/user/ip/whitelist/del/'+urlID,
    method: 'DELETE',
    params: parameter
  })
}
